.status {
  &.online {
    color: COLOR(success);
  }

  &.offline {
    color: COLOR(alert-red-500);
    font-size: FONT_SIZES(medium);
  }
  &.warning {
    color: COLOR(warning);
    font-size: FONT_SIZES(medium);
  }
}

.warning-alert {
  i {
    svg {
      path {
        fill: COLOR(warning);
      }
    }
  }
}

.live-chart-icon {
  i {
    padding-top: 2px;
    svg {
      path {
        stroke: white;
      }
    }
  }
}
.live-tooltip {
  padding: 10px;
  left: 42px;
  top: 155px;
  font-size: 12px;
  max-width: 650px;
}
.historical-tooltip {
  padding: 10px;
  left: 42px;
  top: 155px;
  font-size: 12px;
  max-width: 625px !important;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #f9f9f9;
}
.p-tooltip .p-tooltip-text {
  border-radius: 12px;
  // width: 500px !important;
  white-space: nowrap !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  color: #2a2a57 !important;
}

.settings-tooltip,
.room-view-device-name {
  max-width: 400px !important;
  font-size: 12px !important;
  font-weight: 400;
  border-radius: 16px !important;
}

.notifications-range-tooltip {
  max-width: 400px;
  font-size: 13px;
  font-weight: 400;
  transform: translateY(-10px);
}

.view-settings-device-sensors {
  max-width: 500px;
  font-size: 13px;
  font-weight: 400;
}

.tooltip-address {
  max-width: 500px;
  font-size: 13px;
  font-weight: 400;
}

#tippy-1 {
  z-index: 99999999 !important;
}
.tippy-box {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  z-index: 99999999 !important;
}
.tippy-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
  ul {
    // list-style-type: none;
    padding-left: 15px;
    margin: 0px;
  }
  li {
    margin: 5px 0;
    color: #ff3434;
  }
}
.tippy-arrow {
  color: #ddd;
  transform: translate(81px, 0px) !important;
}

.validations-error {
  max-width: 400px !important;
  font-size: 12px !important;
}
.confirm-button,
.reset-button {
  height: 44px;
  width: 180px;
  border-radius: 12px;
  background-color: #dc2626;
  color: white;
  &.p-button {
    &:focus {
      box-shadow: none;
      outline: none;
      border: none;
    }
    &:hover {
      box-shadow: none;
      outline: none;
      border: none;
    }
  }
}

.cancel-button {
  background-color: transparent;
  color: #323267;
  border: none;
  padding: 0.5em 1em;
  font-size: 16px;
  height: 44px;
  width: 180px;
  font-weight: 500;
  &.p-button {
    &:focus {
      box-shadow: none;
      outline: none;
      border: none;
    }
    &:hover {
      box-shadow: none;
      outline: none;
      border: none;
    }
  }
}

.p-confirm-dialog .p-dialog-header {
  font-size: 16px;
  font-weight: 700;
  color: #42428a;
  .p-dialog-header-close {
    width: 25px;
    height: 25px;
    color: #42428a;
    text-align: center;
    background: #eeeef7;
    border-radius: 5px;
    border: 1px solid #42428a;
    .p-icon-wrapper {
      width: 11px;
      height: 15px;
    }
  }
}
.p-confirm-dialog-message {
  margin-left: 0px;
}
.tooltip-reading-range {
  box-shadow: 0px 4px 8px 0px #11182770;
  max-width: 400px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 16px;
}
